<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/team`"
                title="Employee"
                :columns="columns"
                routerpath="/hr/addemployee"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :canView="canView"
                :viewFields="viewFields"
              >
                <template #action="{ row, getGridData }">
                  <approve-reject
                    title="Employee"
                    :row="row"
                    :getGridData="getGridData"
                    :disableButton="true"
                  />
                </template>
                <template #extraView="{ data, item }">
                  <div v-if="item.field == 'chart'">
                    <div v-if="data.graph_data">
                      <v-select
                        v-model="year"
                        placeholder="None"
                        :options="setYearOptions(data)"
                      />
                    </div>
                    <VueApexCharts
                      v-if="emp_id == data.id"
                      id="chart"
                      type="bar"
                      ref="tooltip"
                      height="300"
                      width="100%"
                      :options="setChartOptions(data)"
                      :series="setChartOptionsSeries(data)"
                    >
                    </VueApexCharts>
                  </div>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import approveReject from "@/components/approveReject.vue";
import { BCol } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";

const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    approveReject,
    GoodTableColumnSearch,
    BCol,
    VueApexCharts,
    vSelect,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      title: "Widget",
      emp_id: 0,
      chartOptions: {
        series: [
          {
            name: "Business",
            data: [],
          },
        ],
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      viewFields: [
        {
          label: "Profile Image",
          field: "profile_image",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Surname",
          field: "surname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Name",
          field: "name",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Father Name",
          field: "fathername",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Email",
          field: "email",
          type: "email",
          class: "col-md-6",
        },
        {
          label: "Chart",
          field: "chart",
          type: "chart",
          class: "col-md-12",
        },
        {
          label: "Mobile",
          field: "mobile",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Gender",
          field: "gender",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Birth Date",
          field: "birthdate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Username",
          field: "username",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Joining Date",
          field: "joiningdate",
          type: "date",
          class: "col-md-4",
        },
        {
          label: "Marital Status",
          field: "maritalstatus",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Anniversary Date",
          field: "anniversarydate",
          type: "date",
          class: "col-md-4",
        },
        {
          label: "Current Address",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Address Line 1",
          field: "curaddressline1",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address Line 2",
          field: "curaddressline2",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pincode",
          field: "curpincode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "City",
          field: "curcity",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Area",
          field: "curarea",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Taluka",
          field: "curtaluka",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "state",
          field: "curstate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Permanent Address",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Address Line 1",
          field: "peraddressline1",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Address Line 2",
          field: "peraddressline2",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pincode",
          field: "perpincode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "City",
          field: "percity",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Area",
          field: "perarea",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Taluka",
          field: "pertaluka",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "state",
          field: "perstate",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "Reporting Authority",
          field: "parent",
          type: "dropdown",
          responseValue: "fullname",
          class: "col-md-4",
        },
        {
          label: "Salary",
          field: "salary",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Designation",
          field: "position",
          type: "dropdown",
          responseValue: "name",
          class: "col-md-4",
        },
        {
          label: "Branch",
          field: "branch",
          type: "dropdown",
          responseValue: "name",
          class: "col-md-4",
        },
        {
          label: "Work Experience",
          field: "experience",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Previous Company Name",
          field: "previous_company",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Previous Designation",
          field: "previous_designation",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Previous Salary",
          field: "previous_salary",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Education",
          field: "education",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Pan No",
          field: "panno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Aadhar No",
          field: "aadharcardno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Bank",
          field: "bankname",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Branch",
          field: "bankbranch",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Account No",
          field: "bankaccno",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "IFSC Code",
          field: "ifsccode",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Aadhar Card Front Side",
          field: "aadharfrontside",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Aadhar Card Back Side",
          field: "aadharbackside",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Pan Card",
          field: "pancard",
          type: "attachment",
          class: "col-md-4",
        },
        {
          label: "Lead Access of",
          field: "leadaccess",
          type: "leadaccess",
          class: "col-md-4",
        },
        {
          label: "Documents",
          field: "heading",
          type: "heading",
          class: "col-md-12",
        },
        {
          label: "ducuments",
          field: "details",
          tableHead: ["Name", "Attachment"],
          tableField: [
            {
              type: "text",
              field: "name",
            },
            {
              type: "attachment",
              field: "attachment",
            },
          ],
          type: "multiple",
          class: "col-md-12",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Employee" : "Add Employee"}`,
        submitRouterPath: "/hr/employee",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getusersbyid`,
        url: `${baseApi}/team`,
        inputFields: [
          {
            type: "password",
            name: "password",
            value: "",
            placeholder: "Enter password",
            required: true,
          },
        ],
      },
      columns: [
        {
          label: "Image",
          field: "profile_image",
          type: "image",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Username",
          field: "username",
          filterOptions: {
            enabled: true,
            placeholder: "Search Username",
          },
        },
        {
          label: "Name",
          field: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        // {
        //   label: 'City',
        //   field: 'city',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search City'
        //   }
        // },
        {
          label: "Gender",
          field: "gender",
          filterOptions: {
            enabled: true,
            placeholder: "Search Gender",
          },
        },
        {
          label: "Reporting Authority",
          field: "parent",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Reporting Authority",
          },
        },
        {
          label: "Designation",
          field: "position",
          type: "dropdown",
          url: "getPosition",
          responseValue: "name",

          filterOptions: {
            enabled: true,
            placeholder: "Search Designation",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
      canView: "",
      year: "",
    };
  },
  computed: {
    setChartOptions() {
      return (item) => {
        if (this.year) {
          let arr = Object.keys(item.graph_data[this.year]).sort();

          return {
            ...this.chartOptions,
            xaxis: {
              categories: arr,
            },
          };
        }
      };
    },
    setChartOptionsSeries() {
      return (item) => {
        if (this.year) {
          let arr = Object.keys(item.graph_data[this.year]).sort();
          let series = [];
          arr.forEach((ele) => {
            series.push(item.graph_data[this.year][ele]);
          });
          return [
            {
              name: "Business",
              data: series,
            },
          ];
        }
      };
    },
    setYearOptions() {
      return (item) => {
        if (this.emp_id != item.id) {
          this.emp_id = item.id;
          this.year = "";
        }
        let arr = Object.keys(item.graph_data).sort((a, b) => b.localeCompare(a));
        if (!this.year) {
          this.year = arr[0];
        }
        return arr;
      };
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Employee") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          this.canView = item.view;

          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
